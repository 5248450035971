import { Composition } from "atomic-layout"
import { GatsbyImageProps } from "gatsby-plugin-image"
import * as React from "react"
import { CSSProperties, useEffect, useState } from "react"
import styled from "styled-components"
import { gap } from "../../../utils/spacing"
import Container from "../../atoms/Container"
import ImageWithText from "../../organisms/ImageWithText"
import {cssSpacing} from "../../../utils/spacing"
import { useLocation } from '@reach/router';
import queryString from "query-string"
import { navigate } from "gatsby"

export type ListingProps = {
  buttonUrl?: object,
  image?: GatsbyImageProps,
  text?: React.ReactNode,
  heading?: React.ReactNode,
  className?: string,
  style?: CSSProperties,
  tags?: string[],
}

type ListingSectionProps = {
  heading?: React.ReactNode,
  items?: ListingProps[],
  initialSelectedTags?: String[]
}

const StyledSelect = styled.div`
  position: relative;
  display: inline-block;
  width: auto;
  ${cssSpacing(1)}

  &::after {
    content: "∨";
    font-size: 1rem;
    top: 6px;
    right: 1.25rem;
    position: absolute;
    transform: translateY(50%);
    pointer-events: none;
  }
  
  select {
    -webkit-appearance: none;
    appearance: none;
    padding-right: 2.5rem;
  }
`

const StyledHeading = styled.div`
  text-align: center;
  ${cssSpacing(1)}
`

const ListingSection = ({
                          heading = null,
                          items,
                          initialSelectedTags = []
                        }: ListingSectionProps) => {

  const [selectedTags, setSelectedTags] = useState([])
  const [visibleListings, setVisibleListings] = useState([])
  const location = useLocation();

  const params = queryString.parse(location?.search);

  useEffect(() => {
    if(params?.filterBy)
    setSelectedTags(params?.filterBy.split(','))
  }, [params])

  useEffect(() => {
    if (!selectedTags.length) {
      // Show all when no tag selected
      setVisibleListings(items)
    } else {
      setVisibleListings(items.filter((item) => {
        return item.tags.filter((tag) => selectedTags.indexOf(tag) !== -1).length
      }))
    }

  }, [selectedTags])

  const tags = items.reduce((prev, item) => {
    if (item?.tags) {
      item.tags.forEach((tag) => {
        if (prev.indexOf(tag) === -1) {
          prev.push(tag)
        }
      })
    }

    return prev
  }, []).sort()

  return (
    <Container>
      {heading &&
      <StyledHeading>{heading}</StyledHeading>
      }

      {tags.length > 0 &&
      <div style={{ textAlign: "center" }}>
        <StyledSelect>
          <select
            value={selectedTags?.[0]}
            onChange={(event) => {
            if (event.target.value) {
              setSelectedTags([event.target.value])
              navigate('/insights/?filterBy=' + encodeURIComponent(event.target.value), {state: {noScroll: true}})
            } else {
              setSelectedTags([])
              navigate('/insights/', {state: {noScroll: true}})
            }
          }}
          >
          <option value={""}>{selectedTags.length ? 'Show all' : 'Filter by topic'}</option>
          {tags.map((tag) => <option key={tag} value={tag}>{tag}</option>)}
          </select>
        </StyledSelect>
      </div>
      }

      <Composition
        templateCols={"1fr"}
        templateColsMd={`repeat(3, minmax(0, 1fr))`}
        gapRow={"var(--section-spacing)"}
        gapCol={gap}
      >
        {visibleListings.map((item, index) => {
          const { image, buttonUrl, heading, text } = item

          return (
            <ImageWithText
              key={"imgtext" + index}
              image={image}
              text={text}
              heading={heading}
              buttonText={"Read More"}
              buttonUrl={buttonUrl}
              imagePosition={"Top"}
              showOffset={false}
            />
          )
        })}
      </Composition>
    </Container>
  )
}

export default ListingSection
